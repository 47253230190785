import React from 'react';

import Layout from '../../components/Layout';
import Service from '../../components/Service';

import Image from '../../../assets/images/services/letterpress.jpg';

const col1 = [
  'High quality ink printed labels on rolls',
  'Four colour process plus spot colours available',
  'Variety of base materials for various applications',
  'Offline sheeting',
  'Ideal for medium to high volume runs'
];

const col2 = [
  'Suitable for printing all static barcode symbologies',
  'Label sizes from 10 x 10 mm up to 250 x 301 mm',
  'Semi-rotary or full-rotary die cutting available',
  'Colourwash to Pantone references',
  'Varnishing or Laminating to protect printed surface'
];

const Letterpress = () => {
  const title = 'Letterpress Labels';

  const metaDescription =
    'High quality ink printed letterpress labels. 4 colour process plus extra spot colour. Rotary or flat bed die cutting. Pantone colour matching.';
  const metaKeywords =
    'letterpress, labels, label, colour, ink, printed, print, process, 4 process, quality, volume, rotary, flat bed, colourwash, plain, printed, pantone,  sprocketing, sheeting, spot, barcode';

  return (
    <Layout title={title} description={metaDescription} keywords={metaKeywords}>
      <Service
        tag="Calf Hey Rotary"
        title={title}
        description={`
          <p>Letterpress printing is best suited to higher volume runs but also offers the ability to match to Pantone colours and print halftone screens.
          <p>With a wide range of options available we can customise labels to your specific details.
        `}
        image={Image}
        col1={col1}
        col2={col2}
        artwork="<strong>ARTWORK FOR LETTERPRESS</strong> - vector artwork is preferred with all text converted and supplied as a .eps, .ai or high resolution .pdf"
      />
    </Layout>
  );
};

export default Letterpress;
